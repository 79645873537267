<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Новый оператор
            </h2>
          </el-col>
        </el-row>
        <el-form ref="form" :rules="rules" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Название" prop="operator_name">
                <el-input v-model="form.operator_name" size="small" placeholder="Название" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Домен для приложений" prop="short_name">
                <el-input v-model="form.short_name" size="small" placeholder="operator-1" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Домен" prop="domain">
                <el-input v-model="form.domain" size="small" placeholder="example.ru" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email" prop="email">
                <el-input v-model="form.email" size="small" placeholder="E-mail" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Токен telegram-бота" prop="bot_token">
                <el-input id="bot_token" v-model="form.bot_token" size="small" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Номер телефона техподдержки" prop="support_phone">
                <el-input id="support_phone" v-model="form.support_phone" size="small" />
              </el-form-item>
            </el-col>
          </el-row>
                    <el-row type="flex" justify="center">
              <el-form-item>
                <el-button @click="showContacts = !showContacts" size="mini">
                  Дополнительные контакты в мессенджерах
                  <i
                    class="el-icon-right"
                    v-bind:class="showContactsIcon"
                  />
                </el-button>
              </el-form-item>
          </el-row>
          <el-collapse-transition>
            <div v-show="showContacts">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Whatsapp" prop="support_whatsapp">
                    <el-input id="support_whatsapp" v-model="form.support_whatsapp" size="small" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Telegram" prop="support_telegram">
                    <el-input id="support_telegram" v-model="form.support_telegram" size="small" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Viber" prop="support_viber">
                    <el-input id="support_viber" v-model="form.support_viber" size="small" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Instagram" prop="support_instagram">
                    <el-input id="support_instagram" v-model="form.support_instagram" size="small" />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-transition>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Логин" prop="login">
                <el-input v-model="form.login" size="small" placeholder="Логин" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Пароль" prop="password">
                <el-input v-model="form.password" size="small" show-password />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Часовой пояс" prop="timezone">
                <el-input-number id="timezone" v-model="form.timezone" :min="-12" :max="12" size="small" />
              </el-form-item>
            </el-col>
             <el-col :span="12">
              <el-form-item prop="several_orgs" label="Несколько юридических лиц?">
                <el-switch
                  v-model="form.several_orgs"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
              <el-form-item prop="can_use_cross_sharing" label="Может использовать функцию кросс-шаринга камер">
                <el-switch
                  v-model="form.can_use_cross_sharing"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button :loading="loading" class="page__active_button" type="primary" icon="el-icon-check" @click="onSave">
        Сохранить
      </el-button>
    </el-footer>
  </div>
</template>
<script>
import { length, required, validatorNumber, validatorSpace } from "@/core/helpers/validators";
import { ApiService } from "@/api";
import { validatorUrl, validatorAllLatine } from "@/core/helpers/validators";

export default {
  name: "OperatorCreate",
  data() {
    return {
      loading: false,
      form: {
        id: "",
        short_name: "",
        login: "",
        password: "",
        status: "",
        link: "",
        timezone: "",
        several_orgs: 0,
        can_use_cross_sharing: 0,
      },
      rules: {
        short_name: [required(), validatorAllLatine, length(3, 200)],
        operator_name: [required(), length(3, 200)],
        domain: [required(), length(0, 300), validatorUrl],
        email: [required()],
        login: [required()],
        password: [required(), length(5, 100), validatorSpace, validatorAllLatine],
        timezone: [required("number")],
        several_orgs: [validatorNumber],
        can_use_cross_sharing: [validatorNumber],
      },
      showContacts: false,
    };
  },
  computed: {
    showContactsIcon() {
      if (this.showContacts) {
        return "el-icon-minus"
      } else {
        return "el-icon-plus"
      }
    }
  },
  methods: {
    onSave() {
      let formIsValid;
      this.$refs["form"].validate((status) => {
        formIsValid = status;
      });

      if (!formIsValid) return false;

      this.loading = true;

      const newOperator = Object.assign({}, this.form);
      ApiService.operators
        .create(newOperator)
        .then((response) => {
          this.$router.push({
            name: "operators:edit",
            params: { id: response.data.id_operator },
          });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.response.data.error || "Неизвестная ошибка",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.page_container {
  padding: 20px;
}
</style>
